(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/dialog-contextual/assets/javascripts/dialog-contextual.js') >= 0) return;  svs.modules.push('/components/tipsen/dialog-contextual/assets/javascripts/dialog-contextual.js');
"use strict";

const {
  ReactIcon,
  ReactIconButton
} = svs.ui;
const {
  Contextual,
  TEXT
} = svs.components.dialog;

const DialogContextual = _ref => {
  let {
    children,
    color = 'fc-sport',
    className = ''
  } = _ref;
  const showDialog = event => {
    svs.components.dialog.api.add(new Contextual({
      area: svs.components.dialog.area.CONTEXTUAL,
      branding: svs.components.dialog.branding.SPORT,
      attachTo: event.currentTarget,
      title: '',
      message: [{
        type: TEXT,
        text: children
      }]
    }));
  };
  return React.createElement(ReactIconButton, {
    className: className,
    onClick: e => showDialog(e)
  }, React.createElement(ReactIcon, {
    color: color,
    icon: "help"
  }));
};
setGlobal('svs.components.tipsen.DialogContextual', DialogContextual);

 })(window);